import { NgModule } from '@angular/core';
import { GoalLocaleTitlePipe } from 'src/app/modules/learning-path/views/admin/goals/pipes/title-locale.pipe';

@NgModule({
    declarations: [
        GoalLocaleTitlePipe
    ],
    exports: [
        GoalLocaleTitlePipe,
    ]
})
export class GoalsSharedModule { }
