import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BaseResultModel } from 'src/app/models/baseResultModel';
import { map } from 'rxjs/internal/operators/map';
import { ModuleModel } from 'src/app/modules/learning-path/models';
import { ResourceService } from 'src/app/modules/resourceLibrary/views/user/services/resource.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';


@Injectable()
export class ModulesCategoryResolver implements Resolve<any> {
  constructor(private tenantService: TenantService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.tenantService.getCategories();
  }
}

@Injectable()
export class ModulesLocaleResolver implements Resolve<any> {
  constructor(private translateService: TranslateService) { }
  //TODO
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.translateService
    .get(["core-learningpath-module_search_advancedfilter_publishedstatus",
          "core-learningpath-module_search_advancedfilter_unpublishedstatus",
          "core-learningpath-module_search_advancedfilter_expiredstatus"])
    .pipe(map(res => {
           var resourceStrings:[] = []
           resourceStrings["core-learningpath-module_search_advancedfilter_publishedstatus"]
                = res["core-learningpath-module_search_advancedfilter_publishedstatus"];
           resourceStrings["core-learningpath-module_search_advancedfilter_unpublishedstatus"]
                = res["core-learningpath-module_search_advancedfilter_unpublishedstatus"];
           resourceStrings["core-learningpath-module_search_advancedfilter_expiredstatus"]
                = res["core-learningpath-module_search_advancedfilter_expiredstatus"];
      return resourceStrings;
    }));
 }
}

@Injectable()
export class ResourceModelResolver implements Resolve<BaseResultModel<ModuleModel>> {
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute
    ,private resourceService: ResourceService)
     { }

  resolve(route: ActivatedRouteSnapshot): Observable<BaseResultModel<ModuleModel>> {
    var resourceId = route.params.id;

    //this.activatedRoute.params.subscribe((params: Params) => {
    //  resourceId = params['id'];
    //});

    return of(new BaseResultModel<ModuleModel>());
    this.resourceService.get(resourceId);
  }
}
