import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { Constants } from 'src/app/shared/shared.constants';
import { TenantNotificationService } from '../../../resourceLibrary/views/user/services/tenant-notification.service';


import { RolesService } from 'src/app/services/roles/roles.service';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { LearningPathPermissions } from '../../permissions';
import { UserService } from 'src/app/services/user/user.service';
import { UserModel } from 'src/app/models/UserModel';
import { TenantNotificationModel } from 'src/app/modules/resourceLibrary/models/tenantNotificationModel';
import { CORE_SESSION_STORAGE } from 'src/app/services/storage/storage.service';
import { StorageService } from 'ngx-webstorage-service';


@Injectable()
export class LearningPath_CanActivateGuard implements CanActivate {

  user: UserModel;
  language: string = "";
  getWelcome: Observable<TenantNotificationModel>;

  constructor( private permissionsService: PermissionsService,
    private tenantNotificationService: TenantNotificationService,
    private userService: UserService,
    public router: Router,
    @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService) {

  }


  canActivate(route: any): Promise<boolean>| Observable < boolean > {

    this.language = this.sessionStorage.get(Constants.USER_BROWSER_LANGUAGE);
    if (this.language === undefined || this.language === '') {
      this.language = 'en-US';
    }
    this.language = this.language.replace(/['"]+/g, '');
    this.getWelcome = this.tenantNotificationService.getTenantNotification(Constants.LEARNING_PATH_WELCOME,this.language);

    // this will be passed from the route config
    // on the data property
    // if (route.)
    if (route._routerState.url.indexOf('welcome') >= 0) {
      return of(true);
    } else {
      return new Promise((resolve, reject) => {
        this.getWelcome
          .subscribe( (response) => {
            if (response != null) {
              this.sessionStorage.set(Constants.LEARNING_PATH_WELCOME, 'false');
            } else {
              this.sessionStorage.set(Constants.LEARNING_PATH_WELCOME, 'true');
            }
            if (response == null) {
                return resolve(true);
            } else {
              const navigateTo = route._urlSegment.segments[route._urlSegment.segments.length - 1].path;
              if(navigateTo.toLowerCase().includes('path'))
              this.router.navigateByUrl(`/admin/learning-path/welcome?navigateTo=${navigateTo}`);
              else{
                return resolve(true);
              }
            }
          });

      });
    }





    //var adminPermissions = new LearningPathPermissions();

    //var requiredPermissions = adminPermissions.GetAdminPermissions();
    //if (this.permissionsService.checkUserPermissions(requiredPermissions, false))
    //  return true;
    //else
    //  return false;
  }
}
