import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { UserModel } from 'src/app/models/UserModel';

@Injectable()
export class UserResolver implements Resolve<Observable<UserModel>> {
  constructor(private userService : UserService) {}

  resolve() {
    return this.userService.getUserInfo();
  }
}