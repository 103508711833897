import { Pipe, PipeTransform } from '@angular/core';
import { GoalLocale } from 'src/app/modules/learning-path/models';

@Pipe({ name: 'goalLocaleTitle' })
export class GoalLocaleTitlePipe implements PipeTransform {

    transform(value: Array<GoalLocale>, lang: string) {
        if (value != null && value.length > 0) {
            const locale = value.find(l => l.locale === lang) || value.shift();
            return locale.title;
        }
        return undefined;
    }
}
