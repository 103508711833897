import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { camelCase } from 'lodash';

import { GoalModel, GoalType } from 'src/app/modules/learning-path/models';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';
import { BaseResultModel } from 'src/app/models/baseResultModel';
import { SearchResponseModel } from 'src/app/models/search';
import { dataGridModel } from 'src/app/models/dataGridModel';

const camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map(v => camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce((result, key) => ({
            ...result, [camelCase(key)]: camelizeKeys(obj[key]),
        }), {});
    }
    return obj;
};

@Injectable()
/**
 * Service class.
 */
export class GoalsService {

    private uri = '/api/goals';
    private appConfigService: AppConfigService;

    constructor(private http: HttpClient, @Optional() appConfigService: AppConfigService) {
        this.appConfigService = appConfigService;
        if (this.appConfigService) {
            const learningPathModule = this.appConfigService.get().tenantConfig.modules.find(x => x.moduleCode === 'LEARNINGPATH');
            const endpoint = learningPathModule.serviceUrl;
            this.uri = `${endpoint}` + this.uri;
        }
    }

    getGoaltypes(): Observable<BaseResultModel<Array<GoalType>>> {
        const endpoint = `${this.uri}/goaltypes`;
        return this.http.get<BaseResultModel<GoalType[]>>(endpoint);
    }

    saveGoal(goal: GoalModel): Observable<BaseResultModel<GoalModel>> {
        const endpoint = `${this.uri}`;
        if (goal.goalId === undefined) {
            return this.http.post<BaseResultModel<GoalModel>>(endpoint, goal);
        }
        return this.http.put<BaseResultModel<GoalModel>>(endpoint, goal);
    }

    deleteGoal(id: number): Observable<BaseResultModel<GoalModel>> {
        const endpoint = `${this.uri}/${id}`;
        return this.http.delete<BaseResultModel<GoalModel>>(endpoint);
    }

    publishGoal(id: number): Observable<BaseResultModel<GoalModel>> {
        const endpoint = `${this.uri}/publish/${id}`;
        return this.http.post<BaseResultModel<GoalModel>>(endpoint, null);
    }

    unpublishGoal(id: number): Observable<BaseResultModel<GoalModel>> {
        const endpoint = `${this.uri}/unpublish/${id}`;
        return this.http.post<BaseResultModel<GoalModel>>(endpoint, null);
    }

    getGoal(id: number): Observable<BaseResultModel<GoalModel>> {
        const endpoint = `${this.uri}/${id}`;
        return this.http.get<BaseResultModel<GoalModel>>(endpoint);
    }

    search<T>(searchParameters: dataGridModel<T>): Observable<SearchResponseModel> {
        const endpoint = '';
        return this.http.post<SearchResponseModel>(`${this.uri}${endpoint}/admin/search`, searchParameters)
            .pipe(tap(t => {
                if (t != null && t.result != null) {
                    t.result.forEach(res => {
                        if (res.items != null) {
                            res.items = JSON.parse(JSON.stringify(camelizeKeys(res.items)));
                        }
                    });
                }
            }));
    }
}
