import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { ResourceLibraryPermissions } from '../../../permissions';


@Injectable()
export class ResourcesReportCanActivateGuard implements CanActivate {
  constructor(private permissionsService: PermissionsService, public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property

    const adminPermissions = new ResourceLibraryPermissions();
    const requiredPermissions = adminPermissions.GetReportPermissions();

    if (this.permissionsService.checkUserPermissions(requiredPermissions, false)) {
      return true;
    } else {
      return false;
    }
  }
}


